/**
 * ShareView - public API
 * Public facing API for Dualog Drive.
 *
 * The version of the OpenAPI document: v1
 * Contact: support@dualog.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DestinationStatusDto = 'NotStarted' | 'Registered' | 'Downloading' | 'Downloaded' | 'Failed' | 'Completed';

export const DestinationStatusDto = {
    NotStarted: 'NotStarted' as DestinationStatusDto,
    Registered: 'Registered' as DestinationStatusDto,
    Downloading: 'Downloading' as DestinationStatusDto,
    Downloaded: 'Downloaded' as DestinationStatusDto,
    Failed: 'Failed' as DestinationStatusDto,
    Completed: 'Completed' as DestinationStatusDto
};

