<dp-header4 class="dp-mb-2">Task Destinations</dp-header4>

<dp-expandable-list
  [items]="packages"
  [loading]="isLoading && packages.length === 0"
  [pageable]="true"
  [expandedRowIds]="expandedRowsIds"
  (expanded)="onDestinationExpanded($event)"
>
  <dp-column field="destinationStatus" width="60px">
    <dp-cell *dpCellDef="let item">
      <dp-header-cell *dpHeaderCellDef></dp-header-cell>
      <dp-icon
        *ngIf="item.status === DestinationStatus.Downloading"
        type="la-sync-alt"
        class="icon"
        [style.background]="'var(--dualog-blue8)'"
      ></dp-icon>
      <dp-icon
        *ngIf="
          item.status === DestinationStatus.Downloaded ||
          item.status === DestinationStatus.Completed
        "
        type="la-check"
        class="icon"
        [style.background]="'var(--dualog-aqua)'"
        color="var(--dualog-white)"
      ></dp-icon>
      <dp-icon
        *ngIf="
          item.status === DestinationStatus.NotStarted ||
          item.status === DestinationStatus.Registered ||
          item.status === DestinationStatus.Failed
        "
        type="la-satellite-dish"
        class="icon"
        [style.background]="'var(--dualog-blue8)'"
      ></dp-icon>
    </dp-cell>
  </dp-column>
  <dp-column field="leafNodeName">
    <dp-header-cell *dpHeaderCellDef>Ship name</dp-header-cell>
    <dp-cell *dpCellDef="let item" [title]="item.leafNodeName">
      <div class="d-flex flex-column" truncate>
        <dp-body-type [title]="item.leafNodeName" [truncate]="true">
          {{ item.leafNodeName }}
        </dp-body-type>
        <dp-progress-bar
          [progress]="calculateProgress(item)"
          [isFullWidth]="true"
        ></dp-progress-bar>
      </div>
    </dp-cell>
  </dp-column>
  <dp-column field="currentVersion">
    <dp-header-cell *dpHeaderCellDef>Status</dp-header-cell>
    <dp-cell *dpCellDef="let item">
      <div class="d-flex flex-column text-align-right">
        <dp-support-type class="no-margin">
          <ng-container
            *ngIf="
              item.status === DestinationStatus.NotStarted;
              else elseIfIsRegistered
            "
          >
            Not started
          </ng-container>
          <ng-template #elseIfIsRegistered>
            <ng-container
              *ngIf="
                item.status === DestinationStatus.Registered;
                else elseIfIsDownloading
              "
            >
              Waiting
            </ng-container>
          </ng-template>
          <ng-template #elseIfIsDownloading>
            <ng-container
              *ngIf="
                item.status === DestinationStatus.Downloading;
                else elseIfIsDownloaded
              "
            >
              Downloading
            </ng-container>
          </ng-template>
          <ng-template #elseIfIsDownloaded>
            <ng-container
              *ngIf="
                item.status === DestinationStatus.Downloaded;
                else elseIfIsFailed
              "
            >
              Pending completion
            </ng-container>
          </ng-template>
          <ng-template #elseIfIsFailed>
            <ng-container
              *ngIf="
                item.status === DestinationStatus.Failed;
                else elseIfIsCompleted
              "
            >
              Failed
            </ng-container>
          </ng-template>
          <ng-template #elseIfIsCompleted>
            <ng-container *ngIf="item.status === DestinationStatus.Completed">
              Completed
            </ng-container>
          </ng-template>
        </dp-support-type>
      </div>
    </dp-cell>
  </dp-column>
  <ng-template dpListExpandTemplate let-item>
    <td width="60px"></td>
    <td>
      <dp-body-type class="dp-mt-8" small block>Duration</dp-body-type>
      <dp-body-type class="dp-mb-8" small block>Started</dp-body-type>
    </td>
    <td colspan="2">
      <dp-support-type class="dp-mt-8" block>
        <span
          *ngIf="
            item.status === DestinationStatus.Downloaded;
            else elseNotDownloadedYet
          "
        >
          {{ getDuration(item.downloadStarted, item.downloadEnded) }}
        </span>
        <ng-template #elseNotDownloadedYet> &nbsp; </ng-template>
      </dp-support-type>
      <dp-support-type class="dp-mb-8" block>
        {{ item.downloadStarted | dpDateTime }}
      </dp-support-type>
    </td>
  </ng-template>
</dp-expandable-list>
